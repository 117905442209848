.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.list .item{
    border-radius: 4px;
    border-bottom: solid 1px silver;
    height: 60px;
    margin-bottom: 10px;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.list .details::after{
    content: "»";
    font-size: 22px;
    position: absolute;
    right: 30px;
    height: auto;
    text-align: center;    
    margin-top: 1rem;
    color: rgb(194, 194, 194);
    
}

.list .icon{
    border-left: solid 3px rgb(1, 50, 105);
    float: left;
    padding: 4px;
    position: relative;
    top: calc(30% - 10px);
    color: rgb(22, 22, 121);
    font-size: 25px;
    width: 10%;
}

.list .details {
    float: right;
    padding-top: -1em;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: space-between;
}

.list p, span{
    padding: 0;
    margin: 0;
}

.list span{
    font-size: 10px;
}