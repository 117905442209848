.product-list {
    width: 100%;
    height: auto;
    min-height: 100px;
    border-bottom: solid 1px #ccc;
    color: #3d3d3d;
    padding: 8px;
}

.product-list h3 {
    font-size: 13px;
    font-weight: bold;
}

.product-badge {
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
    background-color: #2484aa;
    border-radius: 4px;
    font-size: 8pt;
    text-transform: uppercase;
}

.product-left {
    float: left;
}

.product-right {
    float: right;
}

.product-right span {
    float: right;
    text-align: left;
    color: rgb(85, 141, 62) !important;
    font-size: 17px;
    font-weight: bold;
}

.product-list:hover {
    cursor: pointer;
    background-color: rgb(247, 247, 247);
    /* box-shadow: 1px 1px 8px 1px rgba(87, 86, 86, 0.30); */
}