.result {
    display: flex;
    flex-direction: column;
}

.result>.venda .cliente {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px silver;
}

.result>.venda .cliente>h3 {
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(155, 155, 155);
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.result .venda .valor {
    height: 4vh;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: solid 1px silver;
}

.result .venda .valor .left {
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(155, 155, 155);
    font-weight: bold;
    float: left;
}

.result .venda .valor .right {
    font-size: 15px;
    text-transform: uppercase;
    color: rgb(1, 41, 102);
    font-weight: bold;
    float: right;
}

.title {
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(155, 155, 155);
    font-weight: bold;
    padding: 0;
    margin: 0;
    padding-bottom: 3px;
    padding-top: 10px;
}

.itens {
    display: table;
    width: 100%;
}

.item {
    line-height: 30px;
    display: table-row;
    width: 100%;
}

.produto {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;
    width: 70%;
    max-width: 40vw;
}

.preco {
    text-align: right;
    display: table-cell;
    width: 30%;
}

.pagamento {
    height: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: table;
    width: 100%;
    border-bottom: solid 1px silver;
}

.tipoPagamento {
    float: left;
    text-align: left;
    display: table-cell;
    width: 70%;
}

.valorPagamento {
    float: right;
    text-align: right;
    display: table-cell;
    width: 30%;
    font-weight: 700;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    svg,
    .fa-print {
        display: none !important;
    }
    footer {
        display: none !important;
    }
    #filter {
        display: none !important;
    }
}