.product-list {
    width: 100%;
    height: auto;
    border-bottom: solid 1px #ccc;
    color: #3d3d3d;
    display: table;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5px;
    padding-top: 0;
}

.product-list h3 {
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.product-info {
    display: table-row;
}

.product-badge {
    color: white;
    background-color: #2484aa;
    border-radius: 4px;
    font-size: 8pt;
    text-transform: uppercase;
}

.product-seller {
    display: table-row;
    float: left;
    width: 100%;
    padding: 0;
    padding-bottom: 3px;
}

.product-date-value {
    display: table-row;
    width: 100%;
    padding-top: 0;
}

.date {
    display: table-cell;
    width: 50%;
    text-align: left;
}

.value {
    display: table-cell;
    width: 50%;
    text-align: right;
    font-weight: 700;
    color: rgb(1, 41, 102);
}

.product-list:hover {
    cursor: pointer;
    background-color: rgb(247, 247, 247);
    /* box-shadow: 1px 1px 8px 1px rgba(87, 86, 86, 0.30); */
}